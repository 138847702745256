import React, { useEffect } from 'react'
import {LinearProgress} from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
export default function AutoLogin({setAccount}) {
    const {token,email,id} = useParams();
    const nav = useNavigate();
    useEffect(()=>{
        secureLocalStorage.removeItem('user');
        secureLocalStorage.removeItem('token');
        secureLocalStorage.removeItem('accounts');
        secureLocalStorage.removeItem('selected_account');
        axios.get(process.env.REACT_APP_test_url + `/api/login/auto/${email}/${id}`,
        {
            headers:{
                "Authorization":`Bearer ${token}`
            }
        }).then((res)=>{
                console.log(res);
                secureLocalStorage.setItem("token",res.data.token);
                secureLocalStorage.setItem("user",res.data.user)
                secureLocalStorage.setItem('accounts',res.data.accounts);
                secureLocalStorage.setItem('selected_account',res.data.account);
                setAccount(res.data.account)
                nav('/terminal',{replace:true});
            }).catch((err)=>{
                // setError(err.response.data.message);
            })
    },[])
  return (
    <div style={{width:"100%",height:"100vh",backgroundColor:"#000000"}}>
         <LinearProgress sx={{ width: '100%' }} color='inherit' />
    </div>
  )
}
